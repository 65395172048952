// Variables
@import "_variables";

.footer{
  background-color: #DDD;
  padding-top: 15px;
  padding-bottom: 15px;
  .right{
    text-align: right;
  }
  .left{
    text-align: left;
  }

}
.footer p, .footer a{
  color: #666;
  font-size: .7rem;
}
