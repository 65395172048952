@import "_variables";
html,body{
  overflow-x: hidden;
  background-color: #000000;
}
#lightbox{
  display: none;
}
.row.no-margin{
  margin-left: 0;
  margin-right: 0;
}
.component:first-of-type{
  margin-top: 75px;
  @media(max-width: 767px){
    margin-top: 53px;
  }
}
.full-screen-billboard{
  h2{
    font-family: $hero_font!important;
  }
}
.page-id-128{
  .full-screen-billboard{
    h2{
      font-family: $hero_font!important;
    }
    h2, .action-buttons{
      margin-left: 0;
    }
  }
}
p{
  font-size: .9rem;
}
h1, h2, h3,h4{
  text-transform: uppercase;
}
h2{
  color: $purple;
}
h3{
  color: $headline_3_color;
}

a {
    font-family: $emphasis_font;
}
.title-image-back{
  &.light{
    h1{
      text-shadow: none!important;
    }
  }
  .tag{
    z-index: 100!important;
    padding: 10px 25px 10px 25px!important;
  }
}
iframe{
  max-width: 100%!important;
}
#header-1{
  z-index: 1001;
}
.navbar-dark{
  background-color: #999999;
}
.logo-link{
  img{
    max-width: 125px;
  }
}
.header-nav-logo img{
  @media(max-width: 767px){
    height: 50px;
  }
}
.header-nav-navigation{
  margin-top: 10px;
}
.header-nav-navigation li a{
  padding: 10px 15px!important;
  color: #FFFFFF;
}
#header-1 .header-nav-navigation-link{
  font-size: 1rem;
}
#header-1 .btn-listen-live{
  background-color: lighten($dark_button_color, 10%);
  width: 70%;
  margin: 0 15%;
  font-size: 1.3rem;
  border: 1px #fff solid;
  img{
    margin-top: 0;
  }
}
@media(min-width:768px){
  .header-nav-navigation li a:after {
    content: '';
   display: block;
   height: 2px;
   width: 0;
   margin-top: 6px;
   background: transparent;
   transition: width .5s ease, background-color .5s ease;
  }
  .header-nav-navigation li a:hover:after {
    width: 100%;
    background: #FFFFFF;
  }
}

.header-nav-navigation li a:hover{
  color:#FFFFFF;
  background-color: transparent;
}

/* MOBILE NAV */

.navbar-fixed-top .navbar-collapse{
  background: transparent;
  -webkit-box-shadow: none;
    box-shadow:none;
}
@media(max-width:767px){
  .navbar-fixed-top .navbar-collapse li{
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
}
.navbar-fixed-top .navbar-collapse li a{
  color: #FFFFFF;
}
.btn-listen-live{
  width: 100%;
  text-transform: uppercase;
  font-size: 2rem;
  color: #FFFFFF;
}
.btn-listen-live:hover{
  color: #FFFFFF;
}
.btn-listen-live img{
  margin-right: 10px;
  margin-top: 10px;
}
div[id^="sb_wall"]{
  border:none!important;
  background-color: rgba(255,255,255,0.05);
}
.no-margin-grid-block .block, .no-margin-grid-block .block.has_link{
  padding: 0;
}
.boxed-content{
  background-color: #E6E7E8;
  padding: 15px;
  border-radius: 8px;
}
.full-screen-billboard h2{
    @media(max-width: 767px){
      font-size: 2.2rem;
    }
}

.page-break {
  padding: 0px;
  margin-top: 20px;
  width: 100%;

  h2{
    background-color: $purple;
    color: white!important;
    padding: 0 10px;
    line-height: 2em;
    position: relative;
    &:after{
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: $purple;
      transform-origin: bottom left;
      -ms-transform: skew(-24deg, 0deg);
      -webkit-transform: skew(-24deg, 0deg);
      transform: skew(-24deg, 0deg);
    }
  }

}

.styled-grid-row{
  overflow-x: hidden;
  .no-margin-grid-block .block{
    padding: 0px;
  }
  a:hover{
    text-decoration: none;
  }
  .polaroid-text{
    padding-left: 0!important;
  }
  .polaroid {
    h3{
      background-color: $secondary_color;
      position: relative;
      width: 90%;
      //display: inline-block;
      z-index: 100;
      color: #FFFFFF;
      padding: 10px;
      &:after{
        content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: $secondary_color;
      transform-origin: bottom left;
      -ms-transform: skew(-24deg, 0deg);
      -webkit-transform: skew(-24deg, 0deg);
      transform: skew(-24deg, 0deg);
      }
    }
    p{
      background-color: $primary_color;
      color: #FFFFFF;
      position: relative;
      width: 50%;
      padding: 5px 10px;
      text-transform: uppercase;
      font-size: 1rem;
        &:after{
          content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: $primary_color;
        transform-origin: bottom left;
        -ms-transform: skew(-24deg, 0deg);
        -webkit-transform: skew(-24deg, 0deg);
        transform: skew(-24deg, 0deg);
      }
    }
  }
}



/* FORMS */


.form-half {
  width: 49%;
  display: inline-block;
}

.gform_wrapper .gfield_required {
  display: inline;
}

.form-area textarea {
  max-height: 7em;
}
.gform_wrapper ul.gfield_checkbox li br, .gform_wrapper ul.gfield_radio li br{
  display: none;
}
.gform_wrapper .gfield_checkbox li label{
  max-width: 95%;
}
.gform_wrapper .top_label .gfield_label {
}
.gform_wrapper ul li{
  &:before{
    display: none;
  }
}


section {
    a, a img, a h3, a p {
        transition: 0.25s;
    }

    a:not(.btn), a h3 {
        /*border-bottom: 1px solid transparent;*/
    }

    a:not(.btn):hover, a:not(.polaroid-link) h3:hover{
      /*  text-decoration: none;
        border-color: #CCC;*/
    }

    a.polaroid-link:hover{
        h3{
            padding-left: 15px;
        }
        p{
            padding-left: 25px;
        }
    }


    a:hover, a h3:hover{
        color: $purple;
    }


    a img:hover, a > img:hover{
        opacity: .95;
        box-shadow: 0px 0px 5px #AAA;
    }
}

.sidebar-ad{
  img{
    margin: auto;
  }
}

.full-screen-billboard h2 {
    border-bottom: 0px;
}

.action-billboard.dark h2 {
    color: $purple;
    font-weight: bold;
}

.gform_wrapper br {
    display: none;
}

.footer{
    font-family: $emphasis_font;
}
.base-footer{
  background-color: #000000!important;
}
