@import "_variables";
.no-margin-grid-block{
  .block {
    .content{
      bottom: 40%;
      width: 100%;
    }
    .text-block{
      background-color: $comp_color;
      position: absolute;
       z-index: 1000;
       //bottom: 15px;
       width: 80%;
       @media(min-width:480px) and (max-width: 767px){
        width: 50%;
       }
       min-width: 45%;
       display: inline-block;
      p{
        background-color: transparent;
        margin-top: 0;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
      h3{
        font-size: 1.6em;
        line-height: 1.7em;
        background-color: transparent;
      }
      .title-block{
        width: 45%;
        margin-bottom: 10px;
        background-color: $secondary_color;
        position: relative;
        //display: inline-block;
        z-index: 100;
        @media(min-width:768px) and (max-width: 991px){
         width: 65%;
        }
        :after{
          content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: $secondary_color;
        transform-origin: bottom left;
        -ms-transform: skew(-24deg, 0deg);
        -webkit-transform: skew(-24deg, 0deg);
        transform: skew(-24deg, 0deg);
        }

      }
    }
}
