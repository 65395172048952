// Variables
@import "_variables";

.now-playing-billboard{

  font-family: $emphasis_font;

  .btn{
    width: auto;
    text-transform: uppercase;
  }
  .btn-listen-live{
    margin-left: 25%;
  }
  .btn-listen-live img{
    margin-right: 10px;
  }
  /*height: 50vh;*/
  margin-top:70px;
  background-size: cover!important;
  .left{
    padding: 25px 0;
    h3.song-title{
      color: $secondary_color;
      text-transform: uppercase;
      font-size: 1.4em;
    }
    h3.artist{
      color: #3A3131;
      font-weight: bold;
      text-transform: uppercase;
    }
    .current-track{
      width: 85%;
    }
  }
  .right{
    padding: 25px 15px;
    z-index: 100;
    background-color: $secondary_color;
    img{
      border-radius: 0px!important;
      border: 3px #FFF solid;
    }
    p{
      color: #FFFFFF;
      font-size: 1.2em;
      text-transform: uppercase;
    }
    h2{
      text-transform: uppercase;
      margin-top: 10px;
      padding: 0;
      line-height: 1.2em;
    }
    h3{
      color: $dark_button_color;
      font-weight: bold;
      text-transform: uppercase;
    }
    &:before{
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background:$secondary_color;
      transform-origin: bottom left;
      -ms-transform: skew(25deg, 0deg);
      -webkit-transform: skew(25deg, 0deg);
      transform: skew(25deg, 0deg);
    }
  }
  .now-playing-heading{
    background-color: $comp_color;
    position: relative;
    z-index: 100;
    width: 85%;
    padding-left: 0;
    i{
      color: $secondary_color;
      font-size: 1.4rem;
      margin-top: 5px;
      margin-left: 15px;
    }
  }
  .now-playing-heading:after{
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: $comp_color;
    transform-origin: bottom left;
    -ms-transform: skew(-24deg, 0deg);
    -webkit-transform: skew(-24deg, 0deg);
    transform: skew(-24deg, 0deg);
  }
  h2{
    font-weight: bold;
    color: #FFFFFF;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 10px 0;
    margin-left: 25%;
    margin-bottom: 0;
  }
  .phone-number{
    font-size:.9rem;
    color: #FFFFFF;
    width: 100%;
    float:left;
    a{
      color: #FFFFFF!important;
      font-size: 1.2rem;
    }
  }
  i{
    font-size:1.8rem;
  }
  .right{
    text-align: right;
  }
  @media(max-width:767px){
    .row{
      height: 100%;
    }
    h2{
      margin-left: 0;
    }
    text-align: center;
    .btn{
      width: auto;
      color: #FFFFFF;
      text-transform: uppercase;
      margin-left: 0%;
      font-size: 1.6rem;
    }
    .btn-listen-live img{
      margin-right: 10px;
    }
    .left{
     .current-track{
      width: 100%;
      }
      .song-title{
        font-size: 2.6rem;
        color: $body_text_color;
        margin-left:0%;
      }
      .artist{
        font-size: 1.6em;
        margin-left: 0%;
      }
    }
    .right{
      text-align: center;
    }
      i{
        font-size:1.2rem;
      }
      span{

      }
      .phone-number{
        float: left;
        font-size:.8rem;
      }
  }
  .song-title{
    font-size: 2.6rem;
    color: $body_text_color;
    margin-left: 25%;
  }
  .artist{
    font-size: 1.6em;
    margin-left: 25%;
  }
}
