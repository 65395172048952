// Variables
@import "../variables";

.btn{
  border-radius: 0px;
  transition: 0.25s all;
}

.btn-white-on-dark{
    background-color: $secondary_color;
    color: #FFFFFF;
    text-transform: uppercase;
    &:hover{
      background-color: darken($secondary_color,10%)!important;
      color: #FFFFFF!important;
    }
}

.btn-white-on-blue{
  background-color: $q92_blue;
  color: #FFFFFF;
  text-transform: uppercase;
  &:hover{
    background-color: darken($q92_blue,10%);
    color: #FFFFFF;
  }
}
