@import "_variables";

.header-v1{
  padding: 10px 0;
  &.sticky{
    &.stuck{
      //menu when scrolled IF "sticky" set to yes in settings
      .main-navigation{
        li{
          a{
            color:#ffffff!important;//"stuck" link color
          }
        }
      }

    }
  }

  .animated {
    animation-duration: .5s
  }

  &.desktop-header{
    .action-buttons{
      margin-top: 15px;
      @media(max-width: 1200px){
        margin-top: 6px;
      }
    }
  }

  &.transparent{ //if NO color set
    &:not(.stuck){ //when the page hasn't been scrolled
      &.desktop-header .main-navigation a, &.mobile-header .button-container i, &.mobile-header .button-container span, &.mobile-header .button-container{
        //hamburger and main nav links (desktop)
        //color: #FFF;
        text-shadow: none!important;
        color: #FFFFFF!important;
      }
    }
  }


  /* DESKTOP NAV */
  @media(min-width:1001px){
    .main-navigation{
      margin-top: 20px;
        li{
          &.current_page_item{
            a{
              border-bottom: 4px #FFFFFF solid;
              font-family: $header_font;
            }
          }
          &:hover{
            //transition: all ease-in-out 0.15s;
            .sub-menu {
                //transition: all ease-in-out;
            }
          }
          a{
            font-weight:700!important;
            font-size: 1.1rem;
            padding: 10px 15px!important;
            color: #FFFFFF!important;
            font-family: $header_font;
            &:hover{
              //text-decoration: none;
              border-bottom: 4px #FFFFFF solid;
            }
          }
        }
        .sub-menu {
            //background-color: #f9f9f9;
            //min-width: 200px;
            //padding-left: 0;
            //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            li{
              a{
                //width: 100%;
                //padding: 10px 20px;
                &:hover{
                }
              }
            }
        }
    }
  }

  @media(max-width: 1200px){
    .main-navigation{
      margin-top: 10px;
    }
  }


  /* MOBILE NAVIGATION */
  .button-container,.overlay-button-container {
    //buttons for open and close
    color: $body_text_color;

    i, span{
      color: $body_text_color;
    }
  }

  .overlay-menu-container {
    background: #000000!important;
    nav {
      //padding: 50px 0;
    }
    ul {
      li {
        &.menu-item-has-children > a{
          font-family: $header_font;
          &:after{
            //font-family: FontAwesome;
            //content: "\f105";
            //width: 15px;
            //float: right;
          }
          &.open{
            &:after{
              //font-family: FontAwesome;
              //content: "\f107";
              //width: 15px;
              //float: right;
            }
          }
        }

        a {
          color: $body_text_color;
          font-family: $header_font;
        }
        .sub-menu{
          li{
            a{
              color: $body_text_color;
              font-family: $header_font;
            }
          }
        }
      }
    }

    .cta-buttons{
      //margin:2rem 0;
    }
  }
  /* END MOBILE NAVIGATION */
}
