@import "_variables";

.single-event .title-image-back{

}

h5.event-date{
  font-size: .9rem;
  margin: 25px 0 15px 0;
  @media(max-width: 767px){
    margin: 0;
  }
}
.event-content{
  margin: 25px 0;
}

.event-title{
  color: $q92_blue;
}
