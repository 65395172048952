@import "_variables";

h1 {
  color: #FFF;
}
.full-screen-billboard{
  background-size: cover;
}
.full-screen-billboard h2 p{
  font-size: 1em;
  color: #ffffff;
}
.full-screen-billboard h3 p{
  font-size: .75em;
}
