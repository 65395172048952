@import "_variables";
.polaroid{
  box-shadow: 0px 0px 0px;
  border-radius: 0px;
  display: block;
  border: 0px solid #EEE;
  padding: 0px;
  margin-bottom: 10px;

  img{
    border-width: 0px;
  }

  .image{
    border: 0px solid #CCC;
    border-radius: 0px;
    position: relative;
  }

  .image:after {
    content: "";
    position:absolute;
    z-index: -1;
    width:86%;
    bottom: 5px;
    height: 10px;
    left: 7%;
    border-radius: 100px / 5px;
    box-shadow:0 0 18px rgba(0,0,0,0.8);
  }

  .polaroid-text{
    padding-left: 10px
  }

  h3{
    font-weight: bold;
    margin: 10px 0px 0px 0px;
  }

  a:hover{
    text-decoration-color: $body_text_color;
  }

}
