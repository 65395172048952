// Variables
@import "_variables";



.social-bar{
  padding:0;
  margin-bottom: -1px;
  .right{
    background: rgba(255,255,255,1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .left{
    background: rgba($q92_blue,1);
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    z-index: 100;
    &:after{
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: $q92_blue;
      transform-origin: bottom left;
      -ms-transform: skew(-24deg, 0deg);
      -webkit-transform: skew(-24deg, 0deg);
      transform: skew(-24deg, 0deg);
    }
  }


  h3{
    text-align: center;
    color: #FFF;
  }
  #socialConnect{
    padding-left: 0;
    list-style-type: none;
     li{
      display: inline-block;
      float: left;
      width:20%;
      text-align: center;
    }
    li a{
    color: $q92_blue;
      font-size: 2rem;
    }
  }
}

.post img{
  width: 100%
}
.horizontal-post h3{
  margin-top: 0px;
  color: #FFFFFF;
  font-weight: 700;
  @media(max-width: 767px){
    margin-top: 10px;
  }
}

.large-post h3{
  font-size: 2rem;
}
.medium-post h3{
  font-size: 1.5rem;
}
.more-button{
  padding: 15px 100px!important;
  font-size: 1.3rem!important;
  @media(max-width: 767px){
    padding: 15px 75px!important;
  }
  i{
    font-size: 1.1rem;
  }
}
