// Variables
@import "_variables";

#onAirStaff{
  overflow: hidden;
}
.on-air-staff{
  h2{
    color: #000;
    font-size: 1.3em;
  }
  h4{
    font-size: 1.2em;
  }
}
.styled-grid-row.jocks{
  p{
    width: 75%!important;
  }
}
.single-jocks{
  .social-links{
    padding: 20px 0;
    display: inline;
    li{
     display: inline;
     float:left;
     padding: 0 10px;
   }
   li > a > i{
     font-size: 3em;
   }
  }
}
