$primary_color: #666666;
$secondary_color: #334498;
$q92_blue: #334498;
$comp_color: #EC008C;
$body_text_color: #FFFFFF;
$dark_button_color: #414042;
$purple: #334498;
$gray_headline: #58595B;
$headline_3_color: #334498;
$global_font:  'Roboto', serif;
$header_font:  'Rubik', sans-serif;
$emphasis_font: 'Rubik', sans-serif;
$brand_font_1: 'Fredericka the Great', cursive;
$brand_font_2: 'adobe-caslon-pro', serif;
$hero_font: 'Permanent Marker', cursive;
