@import "_variables";
.title-image-back{

  h1{
      font-family: $emphasis_font;
  }

  background-color: #e9e9e9!important;
  background-size: cover!important;
  background-position: top center!important;
  @media(max-width:767px){
    .container{
      padding-left: 0;
      padding-right: 0;
    }
    height: auto;
    background-image: none!important;
    background-color: transparent!important;
  }
}
.title-image-back .tag{
  border: none;
  font-size: 3.5rem;
  left:0;
  background-color: $purple;
  padding: 10px 25px 0px 25px;
  z-index: 1000;
  @media(max-width: 767px){
    position:relative;
    bottom: 0;
    h1{
      font-size: 1.9rem;
    }
  }

  &:after{
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: $purple;
    transform-origin: bottom left;
    -ms-transform: skew(-24deg, 0deg);
    -webkit-transform: skew(-24deg, 0deg);
    transform: skew(-24deg, 0deg);
  }
  @media(max-width: 767px){
    width: 100%;
  }
}
