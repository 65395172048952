// Variables
@import "_variables";
.sports-schedule{
  h2{
    color: #FFFFFF;
    font-size: 2rem;
  }
  border-bottom: 1px #FFF solid;
  margin: 10px 0;
  padding: 15px 0;
}
.sports-stream{
  margin: 5px 0;
  border-bottom: 1px #fff solid;
}
.sports-stream .content-container{
  padding: 10px 0 0;
  border-radius: 5px;
}
.sports-stream .content-container{
  h2{
    color: #FFFFFF;
    font-size: 1.2rem;
    text-transform: none;
  }
  p{
    font-size: 1rem;
  }
  .action-buttons{
    a{
      &:hover{
        color: #FFFFFF;
        text-decoration: none!important;
      }
    }
  }
}
.sports-stream i{
  font-size: 2.9rem;
  color: #FFFFFF;
  &:hover{
    color: #FFFFFF;
    text-decoration: none!important;
  }
}

.sports-stream .btn{
  padding: 0px;
}
.sports-billboard{
  p{
    font-size: 1.4rem;
    color: #FFFFFF;
  }
  .roosters-logo{
    padding: 10px;
    max-width: 60%;
    background: rgba(255,255,255,0.4);
    border-radius: 10px;
    margin: auto;
    -webkit-transition-duration: .5s; /* Safari */
    transition-duration: .5s;
    &:hover{
      background: rgba(255,255,255,0.5);

    }
    img{
      &:hover{
        box-shadow: none!important;
      }
      margin: auto;
    }
  }
}
